import * as React from 'react'
import Head from 'next/head'
import TagManager from 'react-gtm-module'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from '@mui/material/styles'
import { createEmotionSsrAdvancedApproach } from 'tss-react/next/pagesDir'

import theme from '../theme'

import es_AR from '../translations/es-AR.json'
import pt_BR from '../translations/pt-BR.json'
import es_CL from '../translations/es-CL.json'
import es_CO from '../translations/es-CO.json'
import es_CR from '../translations/es-CR.json'
import es_EC from '../translations/es-EC.json'
import es_MX from '../translations/es-MX.json'
import es_PE from '../translations/es-PE.json'
import es_UY from '../translations/es-UY.json'

const { augmentDocumentWithEmotionCache, withAppEmotionCache } = createEmotionSsrAdvancedApproach({ key: 'css' })

function App({ Component, pageProps, router }) {
  const locale = router.locale === 'default' ? 'es-co' : router.locale
  const messages = {
    default: es_CO,
    'es-ar': es_AR,
    'pt-br': pt_BR,
    'es-cl': es_CL,
    'es-co': es_CO,
    'es-cr': es_CR,
    'es-ec': es_EC,
    'es-mx': es_MX,
    'es-pe': es_PE,
    'es-uy': es_UY
  }

  React.useEffect(() => {
    const tagManagerArgs = { gtmId: 'GTM-5KXJLNQ' }
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <>
      <Head>
        <meta name='google-site-verification' content='adShpEa0BmTCkAMvPxL6ujQHSGihRzbYNDE60UJXjY4' />
      </Head>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </IntlProvider>
    </>
  )
}

export { augmentDocumentWithEmotionCache }
export default withAppEmotionCache(App)
